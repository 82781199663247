import React, { Component } from "react";
import styles from "./ConfirmedOffers.module.scss";
import { setTitle } from "../../../component/ThemeManager/helper";
import HorizontalMenu from "../../../component/HorizontalMenu";
import LoginHeader from "../../../component/LoginHeader";
import Loader from "../../../component/Loader";
import ShortCallLoader from "../../../component/Loader/ShortCallLoader";
import { FinePrintModal, getShortNumber } from "../YourAdvance/helper";
import Odometer from "react-odometerjs";
import { get, invoke, keyBy } from "lodash";
import Tooltip from "../../../component/Tooltip/Tooltip";
import Select from "react-select";
import { distributorSelectStyle } from "../../../component/UploadFiles/selectStyle";
import {
  CURRENT_DISTRIBUTOR_LABEL,
  DEFAULT_PARTNER,
} from "../YourAdvance/constants";
import Image from "../../../component/Image";
import {
  ACCEPT_OFFER,
  API_URL,
  ARTIST_API,
  CONFIRMED_OFFER_STEP,
  CONFIRMED_OFFERS_API,
  ERROR_SERVER_DOWN,
  OFFER_SUMMARY,
  TOP_TRACK,
  USER_API,
} from "../constants";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../helper";
import { Collapse } from "@material-ui/core";
import { DEFAULT_FLOW_THROUGH } from "./constants";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ReactComponent as IconDownArrow } from "../../../assets/logos/dropDownArrow.svg";
import ConfirmedOffersV2 from "../ConfirmedOffersV2/ConfirmedOffersV2";

class ConfirmedOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingRange: false,
      shortLoading: false,
      advanceData: {},
      currentData: {
        tail_term: 0,
        tail_rev_share: "0%",
      },
      defaultOffer: {},
      flatOffer: [],
      selectedDeal: "",
      offerStage: {},
      selectedAdvance: 0,
      navigationStatus: {},
      selectedArtist: {},
      availablePartners: [],
      selectedPartner: null,
      partnersOptions: {},
      isFinePrint: false,
      isSaveOffer: false,
      advanceOpened: false,
      minMaxValues: {
        advance: {
          min: 0,
          max: 0,
        },
        royalty: {
          min: 0,
          max: 0,
        },
        term: {
          min: 0,
          max: 0,
        },
      },
      selections: {
        partner: null,
        works: null,
        flow_through: null,
        term: null,
        royalty: null,
        tail_term: null,
        tail_rev_share: null,
      },
      filteredOptions: {},
      groupedValues: {},
      isOfferScreen: false,
    };
  }

  componentDidMount() {
    this.getAdvanceData();
  }

  componentDidUpdate(prevProps, prevState) {
    setTitle("CONFIRMED OFFERS", this.context);
    if (
      get(this.state, "isOfferScreen") &&
      get(prevState, "isOfferScreen") !== get(this.state, "isOfferScreen")
    ) {
      this.getOffers(true);
    }
  }

  getAdvanceData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=false`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (get(res, "status")) {
          this.checkSteps(
            res.data,
            get(this.props, "location.state.fromDashboard"),
          );

          this.setState({
            offerStage: res.data.offerStage,
            availablePartners: get(res, "data.availablePartners", []),
          });
          // this.getOffers(true);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(ERROR_SERVER_DOWN);
      });
  };

  checkSteps = (data, fromDashboard) => {
    if (fromDashboard) {
      if (
        get(data, "offerStage.defaultOffer.isAccepted") ||
        (get(data, "offerStage.verificationStatus.adjustTerms") &&
          get(data, "offerStage.verificationStatus.offerSummary"))
      ) {
        this.props.history.push({
          pathname: ACCEPT_OFFER,
          state: { reviewData: data },
        });
        return false;
      }
      if (!get(data, "renderNewConfirmedOffers")) {
        invoke(this.props, "history.push", OFFER_SUMMARY);
        return false;
      }
    }
    this.getMinMaxRange();
    return true;
  };

  getMinMaxRange = () => {
    this.setState({ loadingRange: true });
    const data = {
      method: "GET",
    };

    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${CONFIRMED_OFFER_STEP}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loadingRange: false,
        });
        if (get(res, "status")) {
          this.setState({
            offerMinMax: {
              min: get(res, "data.confirmedOfferSteps.min"),
              max: get(res, "data.confirmedOfferSteps.max"),
            },
            confirmedOfferSteps: get(res, "data.confirmedOfferSteps"),
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loadingRange: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(ERROR_SERVER_DOWN);
      });
  };

  getPartnerOptionObject = (data) => {
    const availablePartners = this.state.availablePartners.map((op) =>
      get(data.offers[op], `Catalog Only.0.term.1.0.0%`),
    );
    return keyBy(availablePartners, "partner");
  };

  getOffers = (loadDefault) => {
    this.setState({ loading: false, shortLoading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${CONFIRMED_OFFERS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false, shortLoading: false });
        if (res.status) {
          this.setState(
            {
              advanceData: get(res, "data.offers", {}),
              defaultOffer: get(res, "data.selectedOffer", {}),
              currentData: {
                tail_term: get(res, "data.selectedOffer.tail_term", "0%"),
                tail_rev_share: get(
                  res,
                  "data.selectedOffer.tail_rev_share",
                  "0%",
                ),
                partner: get(res, "data.selectedOffer.partner"),
                works: get(res, "data.selectedOffer.works"),
                flow_through: get(res, "data.selectedOffer.flow_through"),
                deal_terms: get(res, "data.selectedOffer.deal_terms"),
                term: get(res, "data.selectedOffer.term"),
                royalty: get(res, "data.selectedOffer.royalty"),
              },
              selectedPartner: get(
                res,
                "data.selectedOffer.partner",
                get(this.state, "availablePartners[0]", null),
              ),
              partnersOptions: this.getPartnerOptionObject(
                get(res, "data", {}),
              ),
            },
            () => {
              this.updateMinMaxValues(
                JSON.parse(JSON.stringify(this.state.advanceData)),
                loadDefault,
              );
            },
          );
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false, shortLoading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  handleSaveOffer = () => {
    this.setState({ loading: false, shortLoading: true, isSaveOffer: true });
    const payload = {
      offer: {
        ...this.state.currentData,
        total_advance: get(this.state, "minMaxValues.advance.min"),
      },
      adjustTerms: true,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${CONFIRMED_OFFERS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
          shortLoading: false,
          isSaveOffer: false,
        });
        if (res.status) {
          this.props.history.push(ACCEPT_OFFER);
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({
          shortLoading: false,
          loading: false,
          isSaveOffer: false,
        });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  toggleFinePrintText = () => {
    this.setState({ isFinePrint: !this.state.isFinePrint });
  };

  matchOrNull = (key, current) => {
    const stateValue = get(this.state, `currentData.${key}`);
    return !stateValue || stateValue === get(current, key);
  };

  matchFlowThrough = (current) => {
    const stateFlowThrough = get(this.state, "currentData.flow_through");
    return (
      stateFlowThrough === undefined ||
      stateFlowThrough === get(current, "flow_through")
    );
  };

  flattenObject(data) {
    const stack = [data];
    const values = [];
    const minMaxValues = {
      advance: { min: Infinity, max: -Infinity },
      royalty: { min: Infinity, max: -Infinity },
      term: { min: Infinity, max: -Infinity },
    };

    while (stack.length > 0) {
      const current = stack.pop();
      if (get(current, "works")) {
        const keysToMatch = ["partner", "works", "term", "royalty"];
        const areKeysMatching = keysToMatch.every((key) =>
          this.matchOrNull(key, current),
        );

        if (areKeysMatching && this.matchFlowThrough(current)) {
          values.push(current);

          const advance = get(current, "total_advance", 0);
          const royalty = parseFloat(get(current, "royalty", 0));
          const term = get(current, "term", 0);

          minMaxValues.advance.min = Math.min(
            minMaxValues.advance.min,
            advance,
          );
          minMaxValues.advance.max = Math.max(
            minMaxValues.advance.max,
            advance,
          );
          minMaxValues.royalty.min = Math.min(
            minMaxValues.royalty.min,
            royalty,
          );
          minMaxValues.royalty.max = Math.max(
            minMaxValues.royalty.max,
            royalty,
          );
          minMaxValues.term.min = Math.min(minMaxValues.term.min, term);
          minMaxValues.term.max = Math.max(minMaxValues.term.max, term);
        }
      }
      for (const key in current) {
        if (current[key] && typeof current[key] === "object") {
          stack.push(current[key]);
        }
      }
    }
    return { values, minMaxValues };
  }

  generateGroupedValues = (data) => {
    const groupedValues = {};
    if (!get(data, "length")) return groupedValues;
    Object.keys(this.state.selections).forEach((field) => {
      groupedValues[field] = [];
    });
    invoke(data, "forEach", (item) => {
      Object.keys(groupedValues).forEach((field) => {
        if (!groupedValues[field].includes(item[field])) {
          groupedValues[field].push(item[field]);
        }
      });
    });
    Object.keys(groupedValues).forEach((field) => {
      groupedValues[field].sort((a, b) =>
        typeof a === "number" ? a - b : invoke(a, "localeCompare", b),
      );
    });

    return groupedValues;
  };

  filterOptions = (data, update = true) => {
    const { selections } = this.state;

    const minMaxValues = {
      advance: { min: Infinity, max: -Infinity },
      royalty: { min: Infinity, max: -Infinity },
      term: { min: Infinity, max: -Infinity },
    };
    const grouped = JSON.parse(JSON.stringify(this.state.groupedValues));
    const filtered = {};
    const fields = Object.keys(grouped);

    fields.forEach((field) => {
      filtered[field] = [];
    });

    data.forEach((item) => {
      let matchesAllSelections = true;
      Object.keys(selections).forEach((selField) => {
        if (
          [null, undefined].indexOf(selections[selField]) === -1 &&
          selections[selField] !== item[selField]
        ) {
          matchesAllSelections = false;
        }
      });
      if (matchesAllSelections) {
        fields.forEach((field) => {
          if (!filtered[field].includes(item[field])) {
            filtered[field].push(item[field]);
          }
        });
        const advance = get(item, "total_advance", 0);
        const royalty = parseFloat(get(item, "royalty", 0));
        const term = get(item, "term", 0);

        minMaxValues.advance.min = Math.min(minMaxValues.advance.min, advance);
        minMaxValues.advance.max = Math.max(minMaxValues.advance.max, advance);
        minMaxValues.royalty.min = Math.min(minMaxValues.royalty.min, royalty);
        minMaxValues.royalty.max = Math.max(minMaxValues.royalty.max, royalty);
        minMaxValues.term.min = Math.min(minMaxValues.term.min, term);
        minMaxValues.term.max = Math.max(minMaxValues.term.max, term);
      }
    });
    Object.keys(filtered).forEach((field) => {
      filtered[field] = [...new Set(filtered[field])].sort((a, b) =>
        typeof a === "number" ? a - b : invoke(a, "localeCompare", b),
      );
    });

    this.setState({
      filteredOptions: update ? filtered : this.state.filteredOptions,
      minMaxValues,
    });
  };

  updateMinMaxValues = (data, firstTime) => {
    const relevantData = this.flattenObject(data);
    if (firstTime) {
      const groupedValues = this.generateGroupedValues(relevantData.values);
      this.filterOptions(relevantData.values);
      this.setState({ groupedValues });
    }
    this.setState({
      flatOffer: [...relevantData.values],
    });
  };

  isPartnerFlow = () => !!get(this.state, "availablePartners.length");

  loaderText = () => {
    if (this.state.isSaveOffer) {
      return (
        <p>
          We are generating <br /> your<span> deal summary</span>
        </p>
      );
    }
    return (
      <p>
        We are retrieving <br /> your<span> offers</span>
      </p>
    );
  };

  getPartnerOptions = () =>
    get(this.state, "availablePartners", []).map((o) => ({
      label: o === DEFAULT_PARTNER ? CURRENT_DISTRIBUTOR_LABEL : o,
      value: o,
    }));

  getSelectedPartner = () =>
    get(this.state, "currentData.partner", null) && {
      label:
        get(this.state, "currentData.partner") === DEFAULT_PARTNER
          ? CURRENT_DISTRIBUTOR_LABEL
          : get(this.state, "currentData.partner"),
      value: get(this.state, "currentData.partner"),
    };

  getWorksOptions = () =>
    this.isPartnerFlow()
      ? Object.keys(
          get(this.state, `advanceData.${this.state.selectedPartner}`, {}),
        ).map((o) => ({
          label: o,
          value: o,
        }))
      : Object.keys(get(this.state, "advanceData", {})).map((o) => ({
          label: o,
          value: o,
        }));

  formatOptionLabel = (op) => (
    <div className={styles.option}>
      {op.value !== DEFAULT_PARTNER && (
        <Image
          src={get(this.state, `partnersOptions.${op.value}.logo`)}
          alt={op.value}
        />
      )}
      <span>{op.label}</span>
    </div>
  );

  handleChangeOffer = (field, value, update = true) => {
    const keyOrder = [
      "partner",
      "works",
      "flow_through",
      "deal_terms",
      "term",
      "royalty",
      "tail_term",
      "tail_rev_share",
    ];

    const currentData = {
      ...get(this.state, "currentData", {}),
      [field]: value,
    };
    const keyIndex = keyOrder.indexOf(field);
    const selections = { ...get(this.state, "selections", {}), [field]: value };
    if (keyIndex !== -1) {
      for (let i = keyIndex + 1; i < keyOrder.length; i++) {
        const nextKey = keyOrder[i];
        delete currentData[nextKey];
        selections[nextKey] = null;
        currentData.tail_term = 0;
        currentData.tail_rev_share = "0%";
        selections.tail_term = null;
        selections.tail_rev_share = null;
      }
    }
    if (field === "deal_terms") {
      delete currentData.royalty;
      delete currentData.term;
      selections.royalty = null;
      selections.term = null;
      if (!update) {
        delete selections[field];
      }
    }
    this.setState(
      (prev) => ({
        currentData,
        selections,
      }),
      () => {
        this.filterOptions(this.state.flatOffer, update);
      },
    );
  };

  renderCustomizeOffers = () => {
    const flowThroughOptions = Object.keys(
      get(
        this.state,
        `advanceData.${
          this.isPartnerFlow()
            ? `${get(this.state, "currentData.partner", "")}.`
            : ""
        }${get(this.state, "currentData.works", "")}`,
        {},
      ),
    ).map(Number);

    const termOptions = Object.keys(
      get(
        this.state,
        `advanceData.${
          this.isPartnerFlow()
            ? `${get(this.state, "currentData.partner", "")}.`
            : ""
        }${get(this.state, "currentData.works", "")}.${get(
          this.state,
          "currentData.flow_through",
          "",
        )}.term`,
        {},
      ),
    ).map(Number);

    const royaltyOptions = Object.keys(
      get(
        this.state,
        `advanceData.${
          this.isPartnerFlow()
            ? `${get(this.state, "currentData.partner", "")}.`
            : ""
        }${get(this.state, "currentData.works", "")}.${get(
          this.state,
          "currentData.flow_through",
          "",
        )}.royalty`,
        {},
      ),
    );

    const tailTermOptions = Object.keys(
      get(
        this.state,
        `advanceData.${
          this.isPartnerFlow()
            ? `${get(this.state, "currentData.partner", "")}.`
            : ""
        }${get(this.state, "currentData.works", "")}.${get(
          this.state,
          "currentData.deal_terms",
          "",
        )}.${get(
          this.state,
          `currentData.${get(this.state, "currentData.deal_terms", "")}`,
          "",
        )}`,
        {},
      ),
    ).map(Number);

    const tailShareOptions = Object.keys(
      get(
        this.state,
        `advanceData.${
          this.isPartnerFlow()
            ? `${get(this.state, "currentData.partner", "")}.`
            : ""
        }${get(this.state, "currentData.works", "")}.${get(
          this.state,
          "currentData.deal_terms",
          "",
        )}.${get(
          this.state,
          `currentData.${get(this.state, "currentData.deal_terms", "")}`,
          "",
        )}.${get(this.state, "currentData.tail_term", "")}`,
        {},
      ),
    ).map(Number);

    return (
      <div className={styles.customizeFundingContainer}>
        <div className={styles.floatHeader}>
          <div className={styles.advanceContainer}>
            <p>Total Advance</p>
            {get(this.state, "currentData.term") ||
            get(this.state, "currentData.royalty") ? (
              <h3>
                $
                {get(this.state, "minMaxValues.advance.min") !== Infinity && (
                  <Odometer
                    value={get(this.state, "minMaxValues.advance.min")}
                    format="(,ddd).dd"
                    duration={400}
                  />
                )}
              </h3>
            ) : (
              <h3>
                $
                {get(this.state, "minMaxValues.advance.min") !== Infinity && (
                  <Odometer
                    value={getShortNumber(
                      get(this.state, `minMaxValues.advance.min`),
                    )}
                    format="(,ddd).dd"
                    duration={400}
                  />
                )}
                {`${getShortNumber(
                  get(this.state, `minMaxValues.advance.min`),
                )}`.replace(/[\d,\.]/g, "")}{" "}
                -{" "}
                {get(this.state, "minMaxValues.advance.max") !== -Infinity && (
                  <Odometer
                    value={getShortNumber(
                      get(this.state, `minMaxValues.advance.max`),
                    )}
                    format="(,ddd).dd"
                    duration={400}
                  />
                )}
                {`${getShortNumber(
                  get(this.state, `minMaxValues.advance.max`),
                )}`.replace(/[\d,\.]/g, "")}
              </h3>
            )}
          </div>
          <div className={styles.royalty}>
            <p>
              <span>Royalty Rate</span>
              <Tooltip
                place="top"
                light
                id="Royalty Rate"
                delay={100}
                content={
                  "This is the percentage of your distribution revenue that we collect that goes toward recouping your advance."
                }
              />
            </p>
            {get(this.state, "currentData.royalty") ||
            get(this.state, "currentData.term") ? (
              <p>{get(this.state, "minMaxValues.royalty.min")}%</p>
            ) : (
              <p>
                {get(this.state, "minMaxValues.royalty.min")}% -{" "}
                {get(this.state, `minMaxValues.royalty.max`)}%
              </p>
            )}
          </div>
          <div className={styles.royalty}>
            <p>
              <span>Time to Recoup</span>
              <Tooltip
                place="top"
                light
                id="Time to Recoup"
                delay={100}
                content={""}
              />
            </p>
            {get(this.state, "currentData.term") ||
            get(this.state, "currentData.royalty") ? (
              <p>{get(this.state, "minMaxValues.term.min")} Years</p>
            ) : (
              <p>
                {get(this.state, "minMaxValues.term.min")} -{" "}
                {get(this.state, `minMaxValues.term.max`)} Years
              </p>
            )}
          </div>
          <div className={styles.submitContainer}>
            <button
              onClick={this.handleSaveOffer}
              disabled={
                !get(this.state, "currentData.term") &&
                !get(this.state, "currentData.royalty")
              }
            >
              Select Deal
            </button>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <h2>Deal Details</h2>
            <Tooltip
              place="top"
              light
              id="Deal details"
              delay={100}
              content={
                "We have analyzed your reports and you can now choose from any of the confirmed advance structures below."
              }
            />
          </div>
          <div className={styles.selectorContainer}>
            {!!get(this.state, "availablePartners.length") && (
              <div className={styles.fieldContainer}>
                <label>
                  <span>Distributor</span>
                  <Tooltip
                    place="top"
                    light
                    id="Distributors"
                    delay={100}
                    content=""
                  />
                </label>
                <Select
                  classNamePrefix="offers"
                  styles={distributorSelectStyle}
                  onChange={(op) => {
                    this.handleChangeOffer("partner", get(op, "value"));
                  }}
                  options={this.getPartnerOptions()}
                  placeholder={`Select a Distributor`}
                  value={this.getSelectedPartner()}
                  components={{ IndicatorSeparator: null }}
                  formatOptionLabel={this.formatOptionLabel}
                  aria-label="partner-select-input"
                />
              </div>
            )}
            <div className={styles.fieldContainer}>
              <label>
                <span>Included Works</span>
                <Tooltip
                  place="top"
                  light
                  id="Included Works"
                  delay={100}
                  content=""
                />
              </label>
              <Select
                classNamePrefix="offers"
                styles={distributorSelectStyle}
                onChange={(op) => {
                  this.handleChangeOffer("works", get(op, "value"));
                }}
                options={this.getWorksOptions()}
                placeholder={`Select One`}
                value={
                  get(this.state, "currentData.works") && {
                    label: get(this.state, "currentData.works"),
                    value: get(this.state, "currentData.works"),
                  }
                }
                components={{ IndicatorSeparator: null }}
                aria-label="works-select-input"
              />
            </div>
            <div className={styles.fieldContainer}>
              <label>
                <span>
                  How much income do you want to keep before recoupment?
                </span>
                <Tooltip
                  place="top"
                  light
                  id="flow_through"
                  delay={100}
                  content="We can pay through a percentage of your monthly income during your deal."
                />
              </label>
              <ToggleButtonGroup
                name="flow_through"
                className={styles.toggleButton}
                exclusive
                value={get(this.state, "currentData.flow_through")}
                onChange={(e, val) => {
                  this.handleChangeOffer("flow_through", val);
                }}
              >
                {(!get(flowThroughOptions, "length")
                  ? DEFAULT_FLOW_THROUGH
                  : flowThroughOptions
                ).map((item) => (
                  <ToggleButton
                    key={`flow_through-${item}`}
                    value={item}
                    disableRipple
                  >
                    {item}%
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
            <div className={styles.fieldContainer}>
              <label>
                <span>Deal Terms</span>
                <Tooltip
                  place="top"
                  light
                  id="deal_terms"
                  delay={100}
                  content=""
                />
              </label>
              <ToggleButtonGroup
                name="deal_terms"
                exclusive
                className={styles.toggleButton}
                value={get(this.state, "currentData.deal_terms")}
                onChange={(e, val) => {
                  this.handleChangeOffer("deal_terms", val, false);
                }}
              >
                <ToggleButton value={"royalty"} disableRipple>
                  <p>Select Recoupment Rate</p>
                  <p>Select the rate you recoup your deal</p>
                </ToggleButton>
                <ToggleButton value={"term"} disableRipple>
                  <p>Select Term Length</p>
                  <p>Select the minimum term length of your deal</p>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            {get(this.state, "currentData.deal_terms") === "term" && (
              <div className={styles.fieldContainer}>
                <label>
                  <span>Minimum Term Length</span>
                  <Tooltip
                    place="top"
                    light
                    id="term"
                    delay={100}
                    content="This is the length of time we project your deal will last."
                  />
                </label>
                <ToggleButtonGroup
                  name="minTerms"
                  exclusive
                  className={styles.toggleButton}
                  value={get(this.state, "currentData.term")}
                  onChange={(e, val) => {
                    this.handleChangeOffer("term", val, false);
                  }}
                >
                  {(!get(termOptions, "length")
                    ? get(
                        this.state,
                        "filteredOptions.term",
                        get(this.state, "groupedValues.term", []),
                      )
                    : termOptions
                  ).map((item) => (
                    <ToggleButton
                      key={`term-${item}`}
                      value={item}
                      disableRipple
                    >
                      {item} Years
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            )}
            {get(this.state, "currentData.deal_terms") === "royalty" && (
              <div className={styles.fieldContainer}>
                <label>
                  <span>Recoupment Rate</span>
                  <Tooltip
                    place="top"
                    light
                    id="royalty"
                    delay={100}
                    content="This is the percentage of your distribution revenue that we collect that goes toward recouping your advance."
                  />
                </label>
                <ToggleButtonGroup
                  name="royalty"
                  exclusive
                  className={styles.toggleButton}
                  value={get(this.state, "currentData.royalty")}
                  onChange={(e, val) => {
                    this.handleChangeOffer("royalty", val, false);
                  }}
                >
                  {(!get(royaltyOptions, "length")
                    ? get(
                        this.state,
                        "filteredOptions.royalty",
                        get(this.state, "groupedValues.royalty", []),
                      )
                    : royaltyOptions
                  ).map((item) => (
                    <ToggleButton
                      key={`royalty-${item}`}
                      value={item}
                      disableRipple
                    >
                      {item}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            )}
          </div>
          <div
            className={styles.advanceTitle}
            onClick={() => {
              if (
                get(this.state, "currentData.term") ||
                get(this.state, "currentData.royalty")
              ) {
                this.setState({
                  advanceOpened: !this.state.advanceOpened,
                });
              }
            }}
          >
            <h3>
              Advance Adjustments <span>(optional)</span>
            </h3>
            <IconDownArrow
              className={this.state.advanceOpened ? styles.opened : ""}
            />
          </div>
          <Collapse
            in={
              this.state.advanceOpened &&
              (get(this.state, "currentData.term") ||
                get(this.state, "currentData.royalty"))
            }
            className={styles.advanceContainer}
          >
            <div className={styles.fieldContainer}>
              <label>
                <span>Post-Recoupment Term</span>
                <Tooltip
                  place="top"
                  light
                  id="tail_term"
                  delay={100}
                  content=""
                />
              </label>
              <ToggleButtonGroup
                name="tail-term"
                exclusive
                className={styles.toggleButton}
                value={get(this.state, "currentData.tail_term", 0)}
                onChange={(e, val) => {
                  this.handleChangeOffer("tail_term", val, false);
                }}
              >
                {(!get(tailTermOptions, "length")
                  ? get(
                      this.state,
                      "filteredOptions.tail_term",
                      get(this.state, "groupedValues.tail_term", []),
                    )
                  : tailTermOptions
                ).map(
                  (item) =>
                    !!item && (
                      <ToggleButton
                        key={`tail-term-${item}`}
                        value={item}
                        disableRipple
                      >
                        {item} Years
                      </ToggleButton>
                    ),
                )}
              </ToggleButtonGroup>
            </div>
            <div className={styles.fieldContainer}>
              <label>
                <span>
                  Percent of royalties withheld after 1 year after recoupment
                </span>
                <Tooltip
                  place="top"
                  light
                  id="tail_rev_share"
                  delay={100}
                  content=""
                />
              </label>
              <ToggleButtonGroup
                name="royalty"
                exclusive
                className={styles.toggleButton}
                value={get(this.state, "currentData.tail_rev_share", "0%")}
                onChange={(e, val) => {
                  this.handleChangeOffer("tail_rev_share", val, false);
                }}
              >
                {(!get(tailShareOptions, "length")
                  ? get(
                      this.state,
                      "filteredOptions.tail_rev_share",
                      get(this.state, "groupedValues.tail_rev_share", []),
                    )
                  : tailShareOptions
                ).map(
                  (item) =>
                    !!parseFloat(item) && (
                      <ToggleButton
                        key={`royalty-${item}`}
                        value={item}
                        disableRipple
                      >
                        {item}
                      </ToggleButton>
                    ),
                )}
              </ToggleButtonGroup>
            </div>
          </Collapse>
        </div>
        <div>
          <button className={styles.linkBtn} onClick={this.toggleFinePrintText}>
            Read the Fine Print
          </button>
        </div>
      </div>
    );
  };

  renderOfferSteps = () => {
    if (get(this.state, "isOfferScreen")) {
      return this.renderCustomizeOffers();
    } else {
      return (
        <ConfirmedOffersV2
          offerMinMax={this.state.offerMinMax}
          confirmedOfferSteps={this.state.confirmedOfferSteps}
          setLoading={(loading) => {
            this.setState({ loading });
          }}
          setParentState={this.setState.bind(this)}
          {...this.props}
        />
      );
    }
  };

  render() {
    return (
      <div className={styles.layoutContainer}>
        <HorizontalMenu offerStage={this.state.offerStage} {...this.props} />
        <div className={styles.funnelContainer}>
          <LoginHeader
            headerTitle={"CONFIRMED OFFERS"}
            renderCustomContent={
              <div className={styles.headerOfferRange}>
                <p>
                  <span>Confirmed Offers Range</span>{" "}
                  <Tooltip
                    place="bottom"
                    light
                    id="offerRange"
                    content={
                      "This is the range of funding you can choose from when selecting an advance. Not all exact dollar figures within the range will be available."
                    }
                    delay={200}
                  />
                </p>
                <div className={styles.rangeNumbers}>
                  ${getShortNumber(get(this.state, "offerMinMax.min"))} - $
                  {getShortNumber(get(this.state, "offerMinMax.max"))}
                </div>
              </div>
            }
          />
          <div className={styles.pageContainer}>
            <div className={`${styles.mainContainer}`}>
              <div className={`${styles.scrollContainer}`}>
                {this.renderOfferSteps()}
              </div>
            </div>
          </div>
          {(this.state.loading || this.state.loadingRange) && (
            <Loader light backgroundNone />
          )}
          {this.state.shortLoading && (
            <ShortCallLoader
              loaderTitle="Hold tight"
              text={this.loaderText()}
              defaultLoader
            />
          )}
          <FinePrintModal
            isOpen={this.state.isFinePrint}
            onRequestClose={this.toggleFinePrintText}
          />
        </div>
      </div>
    );
  }
}

export default ConfirmedOffers;
