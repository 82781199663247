export const DEFAULT_FLOW_THROUGH = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];

export const DEFAULT_TERMS = [1, 2, 3, 5, 8, 20];

export const DEFAULT_ROYALTY = [50, 60, 70, 80, 90];

export const OFFERSUMMARY_STEPS = [
  "MAIN_STEP",
  "MUSIC_QUESTION",
  "FUNDING_QUESTION",
];

export const CONFIRMED_OFFER_QUESTIONS = {
  MAIN_QUESTION: "How do you want to choose your confirmed offers?",
  MUSIC_QUESTION: "What songs do you want to include in your advance?",
  FUNDING_SLIDER_QUESTION: "About how much funding are you looking for?",
};

export const DESCRIPTION_OF_QUESTIONS = {
  MUSIC_QUESTION:
    "Including unreleased songs will increase the amount of funding you are able to receive.",
  FUNDING_SLIDER_QUESTION:
    "We will show you a few options that meet your funding needs.",
};

export const MAIN_QUESTION_OPTIONS = [
  {
    OPTION: "get recommended offers",
    DESCRIPTION: "Answer a few questions to find the deal best for you",
  },
  {
    OPTION: "build your offer",
    DESCRIPTION: "Fully customize every term in your deal",
  },
];

export const MUSIC_OPTION = [
  { OPTION: "just my catalog" },
  { OPTION: "my catalog + 5 songs" },
  { OPTION: "my Catalog + 10 songs" },
];

export const SLIDER_STEP_RANGE = [25000, 100000, 500000];
